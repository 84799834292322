blockquote,
dd,
div,
dl,
dt,
form,
h1,
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
pre,
td,
th,
ul {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  line-height: inherit;
}

a img {
  border: none;
}

meta.foundation-version {
  font-family: "/5.1.0/";
}

meta.foundation-mq-small {
  font-family: "/only screen and (max-width: 40em)/";
  width: 0;
}

meta.foundation-mq-medium {
  font-family: "/only screen and (min-width:40.063em)/";
  width: 40.063em;
}

meta.foundation-mq-large {
  font-family: "/only screen and (min-width:64.063em)/";
  width: 64.063em;
}

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width:90.063em)/";
  width: 90.063em;
}

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width:120.063em)/";
  width: 120.063em;
}

meta.foundation-data-attribute-namespace {
  font-family: false;
}

body,
html {
  height: 100%;
}

*,
:after,
:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  font-size: 100%;
}

a:hover {
  cursor: pointer;
}

embed,
img,
object {
  max-width: 100%;
}

embed,
object {
  height: 100%;
}

img {
  -ms-interpolation-mode: bicubic;
}

#map_canvas embed,
#map_canvas img,
#map_canvas object,
.map_canvas embed,
.map_canvas img,
.map_canvas object {
  max-width: none !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.clearfix {
  *zoom: 1;
}

.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.hide {
  display: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
}

textarea {
  height: auto;
  min-height: 50px;
}

select {
  width: 100%;
}

.row {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 970px;
  *zoom: 1;
}

.row:after,
.row:before {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.row.collapse > .column,
.row.collapse > .columns {
  padding-left: 0;
  padding-right: 0;
  float: left;
}

.row.collapse .row {
  margin-left: 0;
  margin-right: 0;
}

.row .row {
  width: auto;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  margin-top: 0;
  margin-bottom: 0;
  max-width: none;
  *zoom: 1;
}

.row .row:after,
.row .row:before {
  content: " ";
  display: table;
}

.row .row:after {
  clear: both;
}

.row .row.collapse {
  width: auto;
  margin: 0;
  max-width: none;
  *zoom: 1;
}

.row .row.collapse:after,
.row .row.collapse:before {
  content: " ";
  display: table;
}

.row .row.collapse:after {
  clear: both;
}

.column,
.columns {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 100%;
  float: left;
}

@media only screen {
  .column.small-centered,
  .columns.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .column.small-uncentered,
  .columns.small-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left;
  }

  .column.small-uncentered.opposite,
  .columns.small-uncentered.opposite {
    float: right;
  }

  .small-push-0 {
    left: 0;
    right: auto;
  }

  .small-pull-0 {
    right: 0;
    left: auto;
  }

  .small-push-1 {
    left: 8.33333%;
    right: auto;
  }

  .small-pull-1 {
    right: 8.33333%;
    left: auto;
  }

  .small-push-2 {
    left: 16.66667%;
    right: auto;
  }

  .small-pull-2 {
    right: 16.66667%;
    left: auto;
  }

  .small-push-3 {
    left: 25%;
    right: auto;
  }

  .small-pull-3 {
    right: 25%;
    left: auto;
  }

  .small-push-4 {
    left: 33.33333%;
    right: auto;
  }

  .small-pull-4 {
    right: 33.33333%;
    left: auto;
  }

  .small-push-5 {
    left: 41.66667%;
    right: auto;
  }

  .small-pull-5 {
    right: 41.66667%;
    left: auto;
  }

  .small-push-6 {
    left: 50%;
    right: auto;
  }

  .small-pull-6 {
    right: 50%;
    left: auto;
  }

  .small-push-7 {
    left: 58.33333%;
    right: auto;
  }

  .small-pull-7 {
    right: 58.33333%;
    left: auto;
  }

  .small-push-8 {
    left: 66.66667%;
    right: auto;
  }

  .small-pull-8 {
    right: 66.66667%;
    left: auto;
  }

  .small-push-9 {
    left: 75%;
    right: auto;
  }

  .small-pull-9 {
    right: 75%;
    left: auto;
  }

  .small-push-10 {
    left: 83.33333%;
    right: auto;
  }

  .small-pull-10 {
    right: 83.33333%;
    left: auto;
  }

  .small-push-11 {
    left: 91.66667%;
    right: auto;
  }

  .small-pull-11 {
    right: 91.66667%;
    left: auto;
  }

  .column,
  .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }

  .small-1 {
    width: 8.33333%;
  }

  .small-2 {
    width: 16.66667%;
  }

  .small-3 {
    width: 25%;
  }

  .small-4 {
    width: 33.33333%;
  }

  .small-5 {
    width: 41.66667%;
  }

  .small-6 {
    width: 50%;
  }

  .small-7 {
    width: 58.33333%;
  }

  .small-8 {
    width: 66.66667%;
  }

  .small-9 {
    width: 75%;
  }

  .small-10 {
    width: 83.33333%;
  }

  .small-11 {
    width: 91.66667%;
  }

  .small-12 {
    width: 100%;
  }

  [class*="column"] + [class*="column"]:last-child {
    float: right;
  }

  [class*="column"] + [class*="column"].end {
    float: left;
  }

  .small-offset-0 {
    margin-left: 0 !important;
  }

  .small-offset-1 {
    margin-left: 8.33333% !important;
  }

  .small-offset-2 {
    margin-left: 16.66667% !important;
  }

  .small-offset-3 {
    margin-left: 25% !important;
  }

  .small-offset-4 {
    margin-left: 33.33333% !important;
  }

  .small-offset-5 {
    margin-left: 41.66667% !important;
  }

  .small-offset-6 {
    margin-left: 50% !important;
  }

  .small-offset-7 {
    margin-left: 58.33333% !important;
  }

  .small-offset-8 {
    margin-left: 66.66667% !important;
  }

  .small-offset-9 {
    margin-left: 75% !important;
  }

  .small-offset-10 {
    margin-left: 83.33333% !important;
  }

  .small-offset-11 {
    margin-left: 91.66667% !important;
  }

  .small-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left;
  }
}

@media only screen and (min-width: 48em) {
  .column.medium-centered,
  .columns.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .column.medium-uncentered,
  .columns.medium-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left;
  }

  .column.medium-uncentered.opposite,
  .columns.medium-uncentered.opposite {
    float: right;
  }

  .medium-push-0 {
    left: 0;
    right: auto;
  }

  .medium-pull-0 {
    right: 0;
    left: auto;
  }

  .medium-push-1 {
    left: 8.33333%;
    right: auto;
  }

  .medium-pull-1 {
    right: 8.33333%;
    left: auto;
  }

  .medium-push-2 {
    left: 16.66667%;
    right: auto;
  }

  .medium-pull-2 {
    right: 16.66667%;
    left: auto;
  }

  .medium-push-3 {
    left: 25%;
    right: auto;
  }

  .medium-pull-3 {
    right: 25%;
    left: auto;
  }

  .medium-push-4 {
    left: 33.33333%;
    right: auto;
  }

  .medium-pull-4 {
    right: 33.33333%;
    left: auto;
  }

  .medium-push-5 {
    left: 41.66667%;
    right: auto;
  }

  .medium-pull-5 {
    right: 41.66667%;
    left: auto;
  }

  .medium-push-6 {
    left: 50%;
    right: auto;
  }

  .medium-pull-6 {
    right: 50%;
    left: auto;
  }

  .medium-push-7 {
    left: 58.33333%;
    right: auto;
  }

  .medium-pull-7 {
    right: 58.33333%;
    left: auto;
  }

  .medium-push-8 {
    left: 66.66667%;
    right: auto;
  }

  .medium-pull-8 {
    right: 66.66667%;
    left: auto;
  }

  .medium-push-9 {
    left: 75%;
    right: auto;
  }

  .medium-pull-9 {
    right: 75%;
    left: auto;
  }

  .medium-push-10 {
    left: 83.33333%;
    right: auto;
  }

  .medium-pull-10 {
    right: 83.33333%;
    left: auto;
  }

  .medium-push-11 {
    left: 91.66667%;
    right: auto;
  }

  .medium-pull-11 {
    right: 91.66667%;
    left: auto;
  }

  .column,
  .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }

  .medium-1 {
    width: 8.33333%;
  }

  .medium-2 {
    width: 16.66667%;
  }

  .medium-3 {
    width: 25%;
  }

  .medium-4 {
    width: 33.33333%;
  }

  .medium-5 {
    width: 41.66667%;
  }

  .medium-6 {
    width: 50%;
  }

  .medium-7 {
    width: 58.33333%;
  }

  .medium-8 {
    width: 66.66667%;
  }

  .medium-9 {
    width: 75%;
  }

  .medium-10 {
    width: 83.33333%;
  }

  .medium-11 {
    width: 91.66667%;
  }

  .medium-12 {
    width: 100%;
  }

  [class*="column"] + [class*="column"]:last-child {
    float: right;
  }

  [class*="column"] + [class*="column"].end {
    float: left;
  }

  .medium-offset-0 {
    margin-left: 0 !important;
  }

  .medium-offset-1 {
    margin-left: 8.33333% !important;
  }

  .medium-offset-2 {
    margin-left: 16.66667% !important;
  }

  .medium-offset-3 {
    margin-left: 25% !important;
  }

  .medium-offset-4 {
    margin-left: 33.33333% !important;
  }

  .medium-offset-5 {
    margin-left: 41.66667% !important;
  }

  .medium-offset-6 {
    margin-left: 50% !important;
  }

  .medium-offset-7 {
    margin-left: 58.33333% !important;
  }

  .medium-offset-8 {
    margin-left: 66.66667% !important;
  }

  .medium-offset-9 {
    margin-left: 75% !important;
  }

  .medium-offset-10 {
    margin-left: 83.33333% !important;
  }

  .medium-offset-11 {
    margin-left: 91.66667% !important;
  }

  .medium-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left;
  }

  .push-0 {
    left: 0;
    right: auto;
  }

  .pull-0 {
    right: 0;
    left: auto;
  }

  .push-1 {
    left: 8.33333%;
    right: auto;
  }

  .pull-1 {
    right: 8.33333%;
    left: auto;
  }

  .push-2 {
    left: 16.66667%;
    right: auto;
  }

  .pull-2 {
    right: 16.66667%;
    left: auto;
  }

  .push-3 {
    left: 25%;
    right: auto;
  }

  .pull-3 {
    right: 25%;
    left: auto;
  }

  .push-4 {
    left: 33.33333%;
    right: auto;
  }

  .pull-4 {
    right: 33.33333%;
    left: auto;
  }

  .push-5 {
    left: 41.66667%;
    right: auto;
  }

  .pull-5 {
    right: 41.66667%;
    left: auto;
  }

  .push-6 {
    left: 50%;
    right: auto;
  }

  .pull-6 {
    right: 50%;
    left: auto;
  }

  .push-7 {
    left: 58.33333%;
    right: auto;
  }

  .pull-7 {
    right: 58.33333%;
    left: auto;
  }

  .push-8 {
    left: 66.66667%;
    right: auto;
  }

  .pull-8 {
    right: 66.66667%;
    left: auto;
  }

  .push-9 {
    left: 75%;
    right: auto;
  }

  .pull-9 {
    right: 75%;
    left: auto;
  }

  .push-10 {
    left: 83.33333%;
    right: auto;
  }

  .pull-10 {
    right: 83.33333%;
    left: auto;
  }

  .push-11 {
    left: 91.66667%;
    right: auto;
  }

  .pull-11 {
    right: 91.66667%;
    left: auto;
  }
}

@media only screen and (min-width: 64.063em) {
  .column.large-centered,
  .columns.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .column.large-uncentered,
  .columns.large-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left;
  }

  .column.large-uncentered.opposite,
  .columns.large-uncentered.opposite {
    float: right;
  }

  .large-push-0 {
    left: 0;
    right: auto;
  }

  .large-pull-0 {
    right: 0;
    left: auto;
  }

  .large-push-1 {
    left: 8.33333%;
    right: auto;
  }

  .large-pull-1 {
    right: 8.33333%;
    left: auto;
  }

  .large-push-2 {
    left: 16.66667%;
    right: auto;
  }

  .large-pull-2 {
    right: 16.66667%;
    left: auto;
  }

  .large-push-3 {
    left: 25%;
    right: auto;
  }

  .large-pull-3 {
    right: 25%;
    left: auto;
  }

  .large-push-4 {
    left: 33.33333%;
    right: auto;
  }

  .large-pull-4 {
    right: 33.33333%;
    left: auto;
  }

  .large-push-5 {
    left: 41.66667%;
    right: auto;
  }

  .large-pull-5 {
    right: 41.66667%;
    left: auto;
  }

  .large-push-6 {
    left: 50%;
    right: auto;
  }

  .large-pull-6 {
    right: 50%;
    left: auto;
  }

  .large-push-7 {
    left: 58.33333%;
    right: auto;
  }

  .large-pull-7 {
    right: 58.33333%;
    left: auto;
  }

  .large-push-8 {
    left: 66.66667%;
    right: auto;
  }

  .large-pull-8 {
    right: 66.66667%;
    left: auto;
  }

  .large-push-9 {
    left: 75%;
    right: auto;
  }

  .large-pull-9 {
    right: 75%;
    left: auto;
  }

  .large-push-10 {
    left: 83.33333%;
    right: auto;
  }

  .large-pull-10 {
    right: 83.33333%;
    left: auto;
  }

  .large-push-11 {
    left: 91.66667%;
    right: auto;
  }

  .large-pull-11 {
    right: 91.66667%;
    left: auto;
  }

  .column,
  .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }

  .large-1 {
    width: 8.33333%;
  }

  .large-2 {
    width: 16.66667%;
  }

  .large-3 {
    width: 25%;
  }

  .large-4 {
    width: 33.33333%;
  }

  .large-5 {
    width: 41.66667%;
  }

  .large-6 {
    width: 50%;
  }

  .large-7 {
    width: 58.33333%;
  }

  .large-8 {
    width: 66.66667%;
  }

  .large-9 {
    width: 75%;
  }

  .large-10 {
    width: 83.33333%;
  }

  .large-11 {
    width: 91.66667%;
  }

  .large-12 {
    width: 100%;
  }

  [class*="column"] + [class*="column"]:last-child {
    float: right;
  }

  [class*="column"] + [class*="column"].end {
    float: left;
  }

  .large-offset-0 {
    margin-left: 0 !important;
  }

  .large-offset-1 {
    margin-left: 8.33333% !important;
  }

  .large-offset-2 {
    margin-left: 16.66667% !important;
  }

  .large-offset-3 {
    margin-left: 25% !important;
  }

  .large-offset-4 {
    margin-left: 33.33333% !important;
  }

  .large-offset-5 {
    margin-left: 41.66667% !important;
  }

  .large-offset-6 {
    margin-left: 50% !important;
  }

  .large-offset-7 {
    margin-left: 58.33333% !important;
  }

  .large-offset-8 {
    margin-left: 66.66667% !important;
  }

  .large-offset-9 {
    margin-left: 75% !important;
  }

  .large-offset-10 {
    margin-left: 83.33333% !important;
  }

  .large-offset-11 {
    margin-left: 91.66667% !important;
  }

  .large-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left;
  }

  .push-0 {
    left: 0;
    right: auto;
  }

  .pull-0 {
    right: 0;
    left: auto;
  }

  .push-1 {
    left: 8.33333%;
    right: auto;
  }

  .pull-1 {
    right: 8.33333%;
    left: auto;
  }

  .push-2 {
    left: 16.66667%;
    right: auto;
  }

  .pull-2 {
    right: 16.66667%;
    left: auto;
  }

  .push-3 {
    left: 25%;
    right: auto;
  }

  .pull-3 {
    right: 25%;
    left: auto;
  }

  .push-4 {
    left: 33.33333%;
    right: auto;
  }

  .pull-4 {
    right: 33.33333%;
    left: auto;
  }

  .push-5 {
    left: 41.66667%;
    right: auto;
  }

  .pull-5 {
    right: 41.66667%;
    left: auto;
  }

  .push-6 {
    left: 50%;
    right: auto;
  }

  .pull-6 {
    right: 50%;
    left: auto;
  }

  .push-7 {
    left: 58.33333%;
    right: auto;
  }

  .pull-7 {
    right: 58.33333%;
    left: auto;
  }

  .push-8 {
    left: 66.66667%;
    right: auto;
  }

  .pull-8 {
    right: 66.66667%;
    left: auto;
  }

  .push-9 {
    left: 75%;
    right: auto;
  }

  .pull-9 {
    right: 75%;
    left: auto;
  }

  .push-10 {
    left: 83.33333%;
    right: auto;
  }

  .pull-10 {
    right: 83.33333%;
    left: auto;
  }

  .push-11 {
    left: 91.66667%;
    right: auto;
  }

  .pull-11 {
    right: 91.66667%;
    left: auto;
  }
}

meta.foundation-mq-topbar {
  font-family: "/only screen and (min-width:40.063em)/";
  width: 40.063em;
}

.contain-to-grid {
  width: 100%;
  background: #333;
}

.contain-to-grid .top-bar {
  margin-bottom: 0;
}

.fixed {
  width: 100%;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 99;
}

.fixed.expanded:not(.top-bar) {
  overflow-y: auto;
  height: auto;
  width: 100%;
  max-height: 100%;
}

.fixed.expanded:not(.top-bar) .title-area {
  position: fixed;
  width: 100%;
  z-index: 99;
}

.fixed.expanded:not(.top-bar) .top-bar-section {
  z-index: 98;
  margin-top: 45px;
}

.js-generated {
  display: block;
}

.breadcrumbs {
  display: block;
  padding: 0.5625rem 0.875rem;
  overflow: hidden;
  margin-left: 0;
  list-style: none;
  border-style: solid;
  border-width: 1px;
  background-color: #f4f4f4;
  border-color: #dcdcdc;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.breadcrumbs > * {
  margin: 0;
  float: left;
  font-size: 0.6875rem;
  text-transform: uppercase;
}

.breadcrumbs > :focus a,
.breadcrumbs > :hover a {
  text-decoration: underline;
}

.breadcrumbs > * a,
.breadcrumbs > * span {
  text-transform: uppercase;
  color: #008cba;
}

.breadcrumbs > .current,
.breadcrumbs > .current a {
  cursor: default;
  color: #333;
}

.breadcrumbs > .current:focus,
.breadcrumbs > .current:focus a,
.breadcrumbs > .current:hover,
.breadcrumbs > .current:hover a {
  text-decoration: none;
}

.breadcrumbs > .unavailable,
.breadcrumbs > .unavailable a {
  color: #999;
}

.breadcrumbs > .unavailable a:focus,
.breadcrumbs > .unavailable:focus,
.breadcrumbs > .unavailable:hover,
.breadcrumbs > .unavailable:hover a {
  text-decoration: none;
  color: #999;
  cursor: default;
}

.breadcrumbs > :before {
  content: "/";
  color: #aaa;
  margin: 0 0.75rem;
  position: relative;
  top: 1px;
}

.breadcrumbs > :first-child:before {
  content: " ";
  margin: 0;
}

.alert-box {
  border-style: solid;
  border-width: 1px;
  display: block;
  font-weight: 400;
  margin-bottom: 1.25rem;
  position: relative;
  padding: 0.875rem 1.5rem 0.875rem 0.875rem;
  font-size: 0.8125rem;
  background-color: #008cba;
  border-color: #0078a0;
  color: #fff;
}

.alert-box .close {
  font-size: 1.375rem;
  padding: 9px 6px 4px;
  line-height: 0;
  position: absolute;
  top: 50%;
  margin-top: -0.6875rem;
  right: 0.25rem;
  color: #333;
  opacity: 0.3;
}

.alert-box .close:focus,
.alert-box .close:hover {
  opacity: 0.5;
}

.alert-box.radius {
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.alert-box.round {
  -webkit-border-radius: 1000px;
  border-radius: 1000px;
}

.alert-box.success {
  background-color: #43ac6a;
  border-color: #3a945b;
  color: #fff;
}

.alert-box.alert {
  background-color: #f04124;
  border-color: #de2d0f;
  color: #fff;
}

.alert-box.secondary {
  background-color: #e7e7e7;
  border-color: #c7c7c7;
  color: #4f4f4f;
}

.alert-box.warning {
  background-color: #f08a24;
  border-color: #de770f;
  color: #fff;
}

.alert-box.info {
  background-color: #a0d3e8;
  border-color: #74bfdd;
  color: #4f4f4f;
}

.inline-list {
  margin: 0 auto 1.0625rem;
  margin-left: -1.375rem;
  margin-right: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.inline-list > li {
  list-style: none;
  float: left;
  margin-left: 1.375rem;
  display: block;
}

.inline-list > li > * {
  display: block;
}

/**
 * TD 2020-06-25
 *
 * Disabling this due to weird backslash hack
 *
 * Not sure it's doing anything anyway
 */
/*/ .dropdown.button,
button.dropdown {
  position: relative;
  padding-right: 3.5625rem
}*/

.dropdown.button:before,
button.dropdown:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  top: 50%;
  border-width: 0.375rem;
  right: 1.40625rem;
  margin-top: -0.15625rem;
  border-color: #fff transparent transparent;
}

.dropdown.button.tiny,
button.dropdown.tiny {
  padding-right: 2.625rem;
}

.dropdown.button.tiny:before,
button.dropdown.tiny:before {
  border-width: 0.375rem;
  right: 1.125rem;
  margin-top: -0.125rem;
  border-color: #fff transparent transparent;
}

.dropdown.button.small,
button.dropdown.small {
  padding-right: 3.0625rem;
}

.dropdown.button.small:before,
button.dropdown.small:before {
  border-width: 0.4375rem;
  right: 1.3125rem;
  margin-top: -0.15625rem;
  border-color: #fff transparent transparent;
}

.dropdown.button.large,
button.dropdown.large {
  padding-right: 3.625rem;
}

.dropdown.button.large:before,
button.dropdown.large:before {
  border-width: 0.3125rem;
  right: 1.71875rem;
  margin-top: -0.15625rem;
  border-color: #fff transparent transparent;
}

.dropdown.button.secondary:before,
button.dropdown.secondary:before {
  border-color: #333 transparent transparent;
}

div.switch {
  position: relative;
  padding: 0;
  display: block;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 1.25rem;
  height: 2.25rem;
  background: #fff;
  border-color: #ccc;
}

div.switch label {
  position: relative;
  left: 0;
  z-index: 2;
  float: left;
  width: 50%;
  height: 100%;
  margin: 0;
  font-weight: 700;
  text-align: left;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

div.switch input {
  position: absolute;
  z-index: 3;
  opacity: 0;
  width: 100%;
  height: 100%;
  -moz-appearance: none;
}

div.switch input:focus,
div.switch input:hover {
  cursor: pointer;
}

div.switch span:last-child {
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 1;
  display: block;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

div.switch input:not(:checked) + label {
  opacity: 0;
}

div.switch input:checked {
  display: none !important;
}

div.switch input {
  left: 0;
  display: block !important;
}

div.switch input:first-of-type + label,
div.switch input:first-of-type + span + label {
  left: -50%;
}

div.switch input:first-of-type:checked + label,
div.switch input:first-of-type:checked + span + label {
  left: 0;
}

div.switch input:last-of-type + label,
div.switch input:last-of-type + span + label {
  right: -50%;
  left: auto;
  text-align: right;
}

div.switch input:last-of-type:checked + label,
div.switch input:last-of-type:checked + span + label {
  right: 0;
  left: auto;
}

div.switch span.custom {
  display: none !important;
}

form.custom div.switch .hidden-field {
  margin-left: auto;
  position: absolute;
  visibility: visible;
}

div.switch label {
  padding: 0;
  line-height: 2.3rem;
  font-size: 0.875rem;
}

div.switch input:first-of-type:checked ~ span:last-child {
  left: 100%;
  margin-left: -2.1875rem;
}

div.switch span:last-child {
  width: 2.25rem;
  height: 2.25rem;
  border-color: #b3b3b3;
  background: #fff;
  background: -moz-linear-gradient(top, #fff 0, #f2f2f2 100%);
  background: -webkit-linear-gradient(top, #fff 0, #f2f2f2 100%);
  background: linear-gradient(to bottom, #fff 0, #f2f2f2 100%);
  -webkit-box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.07),
    1000px 0 0 1000px #f3faf6, -2px 0 10px 0 rgba(0, 0, 0, 0.07),
    -1000px 0 0 1000px #f5f5f5;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.07), 1000px 0 0 980px #f3faf6,
    -2px 0 10px 0 rgba(0, 0, 0, 0.07), -1000px 0 0 1000px #f5f5f5;
}

div.switch:focus span:last-child,
div.switch:hover span:last-child {
  background: #fff;
  background: -moz-linear-gradient(top, #fff 0, #e6e6e6 100%);
  background: -webkit-linear-gradient(top, #fff 0, #e6e6e6 100%);
  background: linear-gradient(to bottom, #fff 0, #e6e6e6 100%);
}

div.switch:active {
  background: 0 0;
}

div.switch.large {
  height: 2.75rem;
}

div.switch.large label {
  padding: 0;
  line-height: 2.3rem;
  font-size: 1.0625rem;
}

div.switch.large input:first-of-type:checked ~ span:last-child {
  left: 100%;
  margin-left: -2.6875rem;
}

div.switch.large span:last-child {
  width: 2.75rem;
  height: 2.75rem;
}

div.switch.small {
  height: 1.75rem;
}

div.switch.small label {
  padding: 0;
  line-height: 2.1rem;
  font-size: 0.75rem;
}

div.switch.small input:first-of-type:checked ~ span:last-child {
  left: 100%;
  margin-left: -1.6875rem;
}

div.switch.small span:last-child {
  width: 1.75rem;
  height: 1.75rem;
}

div.switch.tiny {
  height: 1.375rem;
}

div.switch.tiny label {
  padding: 0;
  line-height: 1.9rem;
  font-size: 0.6875rem;
}

div.switch.tiny input:first-of-type:checked ~ span:last-child {
  left: 100%;
  margin-left: -1.3125rem;
}

div.switch.tiny span:last-child {
  width: 1.375rem;
  height: 1.375rem;
}

div.switch.radius {
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

div.switch.radius span:last-child {
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

div.switch.round {
  -webkit-border-radius: 1000px;
  border-radius: 1000px;
}

div.switch.round span:last-child {
  -webkit-border-radius: 999px;
  border-radius: 999px;
}

div.switch.round label {
  padding: 0 0.5625rem;
}

@-webkit-keyframes webkitSiblingBugfix {
  from,
  to {
    position: relative;
  }
}

.th {
  line-height: 0;
  display: inline-block;
  border: solid 4px #fff;
  max-width: 100%;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}

.th:focus,
.th:hover {
  -webkit-box-shadow: 0 0 6px 1px rgba(0, 140, 186, 0.5);
  box-shadow: 0 0 6px 1px rgba(0, 140, 186, 0.5);
}

.th.radius {
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.pricing-table {
  border: solid 1px #ddd;
  margin-left: 0;
  margin-bottom: 1.25rem;
}

.pricing-table * {
  list-style: none;
  line-height: 1;
}

.pricing-table .title {
  background-color: #333;
  padding: 0.9375rem 1.25rem;
  text-align: center;
  color: #eee;
  font-weight: 400;
  font-size: 1rem;
  font-family: "Helvetica Neue", Helvetica, Helvetica, Arial, sans-serif;
}

.pricing-table .price {
  background-color: #f6f6f6;
  padding: 0.9375rem 1.25rem;
  text-align: center;
  color: #333;
  font-weight: 400;
  font-size: 2rem;
  font-family: "Helvetica Neue", Helvetica, Helvetica, Arial, sans-serif;
}

.pricing-table .description {
  background-color: #fff;
  padding: 0.9375rem;
  text-align: center;
  color: #777;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.4;
  border-bottom: dotted 1px #ddd;
}

.pricing-table .bullet-item {
  background-color: #fff;
  padding: 0.9375rem;
  text-align: center;
  color: #333;
  font-size: 0.875rem;
  font-weight: 400;
  border-bottom: dotted 1px #ddd;
}

.pricing-table .cta-button {
  background-color: #fff;
  text-align: center;
  padding: 1.25rem 1.25rem 0;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotate {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.slideshow-wrapper {
  position: relative;
}

.slideshow-wrapper ul {
  list-style-type: none;
  margin: 0;
}

.slideshow-wrapper ul li,
.slideshow-wrapper ul li .orbit-caption {
  display: none;
}

.slideshow-wrapper ul li:first-child {
  display: block;
}

.slideshow-wrapper .orbit-container {
  background-color: transparent;
}

.slideshow-wrapper .orbit-container li,
.slideshow-wrapper .orbit-container li .orbit-caption {
  display: block;
}

.preloader {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  border: solid 3px;
  border-color: #555 #fff;
  -webkit-border-radius: 1000px;
  border-radius: 1000px;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotate;
  -moz-animation-duration: 1.5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -o-animation-name: rotate;
  -o-animation-duration: 1.5s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
  animation-name: rotate;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.orbit-container {
  overflow: hidden;
  width: 100%;
  position: relative;
  background: 0 0;
}

.orbit-container .orbit-slides-container {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-transform: translateZ(0);
}

.orbit-container .orbit-slides-container img {
  display: block;
  max-width: 100%;
}

.orbit-container .orbit-slides-container > * {
  position: absolute;
  top: 0;
  width: 100%;
  margin-left: 100%;
}

.orbit-container .orbit-slides-container > :first-child {
  margin-left: 0;
}

.orbit-container .orbit-slides-container > * .orbit-caption {
  position: absolute;
  bottom: 0;
  background-color: rgba(51, 51, 51, 0.8);
  color: #fff;
  width: 100%;
  padding: 0.625rem 0.875rem;
  font-size: 0.875rem;
}

.orbit-container .orbit-slide-number {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
  color: #fff;
  background: rgba(0, 0, 0, 0);
  z-index: 10;
}

.orbit-container .orbit-slide-number span {
  font-weight: 700;
  padding: 0.3125rem;
}

.orbit-container .orbit-timer {
  position: absolute;
  top: 12px;
  right: 10px;
  height: 6px;
  width: 100px;
  z-index: 10;
}

.orbit-container .orbit-timer .orbit-progress {
  height: 3px;
  background-color: rgba(255, 255, 255, 0.3);
  display: block;
  width: 0;
  position: relative;
  right: 20px;
  top: 5px;
}

.orbit-container .orbit-timer > span {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 11px;
  height: 14px;
  border: solid 4px #fff;
  border-top: none;
  border-bottom: none;
}

.orbit-container .orbit-timer.paused > span {
  right: -4px;
  top: 0;
  width: 11px;
  height: 14px;
  border: inset 8px;
  border-right-style: solid;
  border-color: transparent transparent transparent #fff;
}

.orbit-container .orbit-timer.paused > span.dark {
  border-color: transparent transparent transparent #333;
}

.orbit-container:hover .orbit-timer > span {
  display: block;
}

.orbit-container .orbit-next,
.orbit-container .orbit-prev {
  position: absolute;
  top: 45%;
  margin-top: -25px;
  width: 36px;
  height: 60px;
  line-height: 50px;
  color: #fff;
  background-color: none;
  text-indent: -9999px !important;
  z-index: 10;
}

.orbit-container .orbit-next:hover,
.orbit-container .orbit-prev:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.orbit-container .orbit-next > span,
.orbit-container .orbit-prev > span {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  display: block;
  width: 0;
  height: 0;
  border: inset 10px;
}

.orbit-container .orbit-prev {
  left: 0;
}

.orbit-container .orbit-prev > span {
  border-right-style: solid;
  border-color: transparent;
  border-right-color: #fff;
}

.orbit-container .orbit-prev:hover > span {
  border-right-color: #fff;
}

.orbit-container .orbit-next {
  right: 0;
}

.orbit-container .orbit-next > span {
  border-color: transparent;
  border-left-style: solid;
  border-left-color: #fff;
  left: 50%;
  margin-left: -4px;
}

.orbit-container .orbit-next:hover > span {
  border-left-color: #fff;
}

.orbit-bullets-container {
  text-align: center;
}

.orbit-bullets {
  margin: 0 auto 30px;
  overflow: hidden;
  position: relative;
  top: 10px;
  float: none;
  text-align: center;
  display: block;
}

.orbit-bullets li {
  display: inline-block;
  width: 0.5625rem;
  height: 0.5625rem;
  background: #ccc;
  float: none;
  margin-right: 6px;
  -webkit-border-radius: 1000px;
  border-radius: 1000px;
}

.orbit-bullets li.active {
  background: #999;
}

.orbit-bullets li:last-child {
  margin-right: 0;
}

.touch .orbit-bullets,
.touch .orbit-container .orbit-next,
.touch .orbit-container .orbit-prev {
  display: none;
}

@media only screen and (min-width: 40.063em) {
  .touch .orbit-container .orbit-next,
  .touch .orbit-container .orbit-prev {
    display: inherit;
  }

  .touch .orbit-bullets {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .orbit-stack-on-small .orbit-slides-container {
    height: auto !important;
  }

  .orbit-stack-on-small .orbit-slides-container > * {
    position: relative;
    margin-left: 0 !important;
  }

  .orbit-stack-on-small .orbit-bullets,
  .orbit-stack-on-small .orbit-next,
  .orbit-stack-on-small .orbit-prev,
  .orbit-stack-on-small .orbit-timer {
    display: none;
  }
}

[data-magellan-expedition] {
  background: #fff;
  z-index: 50;
  min-width: 100%;
  padding: 10px;
}

[data-magellan-expedition] .sub-nav,
[data-magellan-expedition] .sub-nav dd {
  margin-bottom: 0;
}

[data-magellan-expedition] .sub-nav a {
  line-height: 1.8em;
}

.tabs {
  *zoom: 1;
  margin-bottom: 0 !important;
}

.tabs:after,
.tabs:before {
  content: " ";
  display: table;
}

.tabs:after {
  clear: both;
}

.tabs dd {
  position: relative;
  margin-bottom: 0 !important;
  top: 1px;
  float: left;
}

.tabs dd > a {
  display: block;
  background: #efefef;
  color: #222;
  padding-top: 1rem;
  padding-right: 2rem;
  padding-bottom: 1.0625rem;
  padding-left: 2rem;
  font-family: "Helvetica Neue", Helvetica, Helvetica, Arial, sans-serif;
  font-size: 1rem;
}

.tabs dd > a:hover {
  background: #e1e1e1;
}

.tabs dd.active a {
  background: #fff;
}

.tabs.radius dd:first-child a {
  -moz-border-radius-bottomleft: 3px;
  -moz-border-radius-topleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.tabs.radius dd:last-child a {
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.tabs.vertical dd {
  position: inherit;
  float: none;
  display: block;
  top: auto;
}

.tabs-content {
  *zoom: 1;
  margin-bottom: 1.5rem;
  width: 100%;
}

.tabs-content:after,
.tabs-content:before {
  content: " ";
  display: table;
}

.tabs-content:after {
  clear: both;
}

.tabs-content > .content {
  display: none;
  float: left;
  padding: 0.9375rem 0;
  width: 100%;
}

.tabs-content > .content.active {
  display: block;
}

.tabs-content > .content.contained {
  padding: 0.9375rem;
}

.tabs-content.vertical {
  display: block;
}

.tabs-content.vertical > .content {
  padding: 0 0.9375rem;
}

@media only screen and (min-width: 40.063em) {
  .tabs.vertical {
    width: 20%;
    float: left;
    margin-bottom: 1.25rem;
  }

  .tabs-content.vertical {
    width: 80%;
    float: left;
    margin-left: -1px;
  }
}

ul.pagination {
  display: block;
  height: 1.5rem;
  margin-left: -0.3125rem;
}

ul.pagination li {
  height: 1.5rem;
  color: #222;
  font-size: 0.875rem;
  margin-left: 0.3125rem;
}

ul.pagination li a {
  display: block;
  padding: 0.0625rem 0.625rem;
  color: #999;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

ul.pagination li a:focus,
ul.pagination li:hover a {
  background: #e6e6e6;
}

ul.pagination li.unavailable a {
  cursor: default;
  color: #999;
}

ul.pagination li.unavailable a:focus,
ul.pagination li.unavailable:hover a {
  background: 0 0;
}

ul.pagination li.current a {
  background: #008cba;
  color: #fff;
  font-weight: 700;
  cursor: default;
}

ul.pagination li.current a:focus,
ul.pagination li.current a:hover {
  background: #008cba;
}

ul.pagination li {
  float: left;
  display: block;
}

.pagination-centered {
  text-align: center;
}

.pagination-centered ul.pagination li {
  float: none;
  display: inline-block;
}

.side-nav {
  display: block;
  margin: 0;
  padding: 0.875rem 0;
  list-style-type: none;
  list-style-position: inside;
  font-family: "Helvetica Neue", Helvetica, Helvetica, Arial, sans-serif;
}

.side-nav li {
  margin: 0 0 0.4375rem;
  font-size: 0.875rem;
}

.side-nav li a:not(.button) {
  display: block;
  color: #008cba;
}

.side-nav li a:not(.button):focus,
.side-nav li a:not(.button):hover {
  color: #1cc7ff;
}

.side-nav li.active > a:first-child:not(.button) {
  color: #1cc7ff;
  font-weight: 400;
  font-family: "Helvetica Neue", Helvetica, Helvetica, Arial, sans-serif;
}

.side-nav li.divider {
  border-top: 1px solid;
  height: 0;
  padding: 0;
  list-style: none;
  border-top-color: #fff;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

@media only screen and (max-width: 767px) {
  .small-only-text-left {
    text-align: left !important;
  }

  .small-only-text-right {
    text-align: right !important;
  }

  .small-only-text-center {
    text-align: center !important;
  }

  .small-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen {
  .small-text-left {
    text-align: left !important;
  }

  .small-text-right {
    text-align: right !important;
  }

  .small-text-center {
    text-align: center !important;
  }

  .small-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 40.063em) and (max-width: 64em) {
  .medium-only-text-left {
    text-align: left !important;
  }

  .medium-only-text-right {
    text-align: right !important;
  }

  .medium-only-text-center {
    text-align: center !important;
  }

  .medium-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 40.063em) {
  .medium-text-left {
    text-align: left !important;
  }

  .medium-text-right {
    text-align: right !important;
  }

  .medium-text-center {
    text-align: center !important;
  }

  .medium-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 64.063em) and (max-width: 90em) {
  .large-only-text-left {
    text-align: left !important;
  }

  .large-only-text-right {
    text-align: right !important;
  }

  .large-only-text-center {
    text-align: center !important;
  }

  .large-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 64.063em) {
  .large-text-left {
    text-align: left !important;
  }

  .large-text-right {
    text-align: right !important;
  }

  .large-text-center {
    text-align: center !important;
  }

  .large-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 90.063em) and (max-width: 120em) {
  .xlarge-only-text-left {
    text-align: left !important;
  }

  .xlarge-only-text-right {
    text-align: right !important;
  }

  .xlarge-only-text-center {
    text-align: center !important;
  }

  .xlarge-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 90.063em) {
  .xlarge-text-left {
    text-align: left !important;
  }

  .xlarge-text-right {
    text-align: right !important;
  }

  .xlarge-text-center {
    text-align: center !important;
  }

  .xlarge-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 120.063em) and (max-width: 99999999em) {
  .xxlarge-only-text-left {
    text-align: left !important;
  }

  .xxlarge-only-text-right {
    text-align: right !important;
  }

  .xxlarge-only-text-center {
    text-align: center !important;
  }

  .xxlarge-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 120.063em) {
  .xxlarge-text-left {
    text-align: left !important;
  }

  .xxlarge-text-right {
    text-align: right !important;
  }

  .xxlarge-text-center {
    text-align: center !important;
  }

  .xxlarge-text-justify {
    text-align: justify !important;
  }
}

.subheader {
  line-height: 1.4;
  color: #6f6f6f;
  font-weight: 400;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}

hr {
  border: solid #ddd;
  border-width: 1px 0 0;
  clear: both;
  margin: 1.25rem 0 1.1875rem;
  height: 0;
}

em,
i {
  /* font-style: italic; Fixes DX-4945 */
  line-height: inherit;
}

b,
strong {
  font-weight: 700;
  line-height: inherit;
}

small {
  font-size: 60%;
  line-height: inherit;
}

code {
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: 700;
  color: #bd260d;
}

dl,
ol,
ul {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  list-style-position: outside;
  font-family: inherit;
}

ul {
  margin-left: 1rem;
}

ul.no-bullet {
  margin-left: 0;
}

ul.no-bullet li ol,
ul.no-bullet li ul {
  margin-left: 1.25rem;
  margin-bottom: 0;
  list-style: none;
}

ul li ol,
ul li ul {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

ul.circle li ul,
ul.disc li ul,
ul.square li ul {
  list-style: inherit;
}

ul.square {
  list-style-type: square;
  margin-left: 1.1rem;
}

ul.circle {
  list-style-type: circle;
  margin-left: 1.1rem;
}

ul.disc {
  list-style-type: disc;
  margin-left: 1.1rem;
}

ul.no-bullet {
  list-style: none;
}

ol {
  margin-left: 1.4rem;
}

ol li ol,
ol li ul {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl dt {
  margin-bottom: 0.3rem;
  font-weight: 700;
}

dl dd {
  margin-bottom: 0.75rem;
}

abbr,
acronym {
  text-transform: uppercase;
  font-size: 90%;
  color: #222;
  border-bottom: 1px dotted #ddd;
  cursor: help;
}

abbr {
  text-transform: none;
}

.vcard {
  display: inline-block;
  margin: 0 0 1.25rem;
  border: 1px solid #ddd;
  padding: 0.625rem 0.75rem;
}

.vcard li {
  margin: 0;
  display: block;
}

.vcard .fn {
  font-weight: 700;
  font-size: 0.9375rem;
}

.vevent .summary {
  font-weight: 700;
}

.vevent abbr {
  cursor: default;
  text-decoration: none;
  font-weight: 700;
  border: none;
  padding: 0 0.0625rem;
}

@media only screen and (min-width: 40.063em) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.4;
  }

  h1 {
    font-size: 2.75rem;
  }

  h2 {
    font-size: 2.3125rem;
  }

  h3 {
    font-size: 1.6875rem;
  }

  h4 {
    font-size: 1.4375rem;
  }
}

.print-only {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .hide-on-print {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }

  .hide-for-print {
    display: none !important;
  }

  .show-for-print {
    display: inherit !important;
  }
}

.split.button {
  position: relative;
  padding-right: 5.0625rem;
}

.split.button span {
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-left: solid 1px;
}

.split.button span:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: inset;
  left: 50%;
}

.split.button span:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.split.button span {
  border-left-color: rgba(255, 255, 255, 0.5);
  width: 3.09375rem;
}

.split.button span:before {
  border-top-style: solid;
  border-width: 0.375rem;
  top: 48%;
  margin-left: -0.375rem;
  border-color: #fff transparent transparent;
}

.split.button.secondary span {
  border-left-color: rgba(255, 255, 255, 0.5);
}

.split.button.alert span,
.split.button.success span {
  border-left-color: rgba(255, 255, 255, 0.5);
}

.split.button.tiny {
  padding-right: 3.75rem;
}

.split.button.tiny span {
  width: 2.25rem;
}

.split.button.tiny span:before {
  border-top-style: solid;
  border-width: 0.375rem;
  top: 48%;
  margin-left: -0.375rem;
}

.split.button.small {
  padding-right: 4.375rem;
}

.split.button.small span {
  width: 2.625rem;
}

.split.button.small span:before {
  border-top-style: solid;
  border-width: 0.4375rem;
  top: 48%;
  margin-left: -0.375rem;
}

.split.button.large {
  padding-right: 5.5rem;
}

.split.button.large span {
  width: 3.4375rem;
}

.split.button.large span:before {
  border-top-style: solid;
  border-width: 0.3125rem;
  top: 48%;
  margin-left: -0.375rem;
}

.split.button.expand {
  padding-left: 2rem;
}

.split.button.secondary span:before {
  border-color: #333 transparent transparent;
}

.split.button.radius span {
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.split.button.round span {
  -moz-border-radius-bottomright: 1000px;
  -moz-border-radius-topright: 1000px;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}

.reveal-modal-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #000;
  background: rgba(0, 0, 0, 0.45);
  z-index: 98;
  display: none;
  top: 0;
  left: 0;
}

.reveal-modal,
dialog {
  visibility: hidden;
  display: none;
  position: absolute;
  left: 50%;
  z-index: 99;
  height: auto;
  margin-left: -40%;
  width: 80%;
  background-color: #fff;
  padding: 1.25rem;
  border: solid 1px #666;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  top: 6.25rem;
}

.reveal-modal .column,
.reveal-modal .columns,
dialog .column,
dialog .columns {
  min-width: 0;
}

.reveal-modal > :first-child,
dialog > :first-child {
  margin-top: 0;
}

.reveal-modal > :last-child,
dialog > :last-child {
  margin-bottom: 0;
}

.reveal-modal .close-reveal-modal,
dialog .close-reveal-modal {
  font-size: 1.375rem;
  line-height: 1;
  position: absolute;
  top: 0.5rem;
  right: 0.6875rem;
  color: #aaa;
  font-weight: 700;
  cursor: pointer;
}

dialog[open] {
  display: block;
  visibility: visible;
}

@media only screen and (min-width: 40.063em) {
  .reveal-modal,
  dialog {
    padding: 1.875rem;
    top: 6.25rem;
  }

  .reveal-modal.tiny,
  dialog.tiny {
    margin-left: -15%;
    width: 30%;
  }

  .reveal-modal.small,
  dialog.small {
    margin-left: -20%;
    width: 40%;
  }

  .reveal-modal.medium,
  dialog.medium {
    margin-left: -30%;
    width: 60%;
  }

  .reveal-modal.large,
  dialog.large {
    margin-left: -35%;
    width: 70%;
  }

  .reveal-modal.xlarge,
  dialog.xlarge {
    margin-left: -47.5%;
    width: 95%;
  }
}

@media print {
  .reveal-modal,
  dialog {
    background: #fff !important;
  }
}

.has-tip {
  border-bottom: dotted 1px #ccc;
  cursor: help;
  font-weight: 700;
  color: #333;
}

.has-tip:focus,
.has-tip:hover {
  border-bottom: dotted 1px #003f54;
  color: #008cba;
}

.has-tip.tip-left,
.has-tip.tip-right {
  float: none !important;
}

.tap-to-close {
  display: block;
  font-size: 0.625rem;
  color: #777;
  font-weight: 400;
}

@media only screen and (min-width: 40.063em) {
  .tooltip > .nub {
    border-color: transparent transparent #333;
    top: -10px;
  }

  .tooltip.tip-top > .nub {
    border-color: #333 transparent transparent;
    top: auto;
    bottom: -10px;
  }

  .tooltip.tip-left,
  .tooltip.tip-right {
    float: none !important;
  }

  .tooltip.tip-left > .nub {
    border-color: transparent transparent transparent #333;
    right: -10px;
    left: auto;
    top: 50%;
    margin-top: -5px;
  }

  .tooltip.tip-right > .nub {
    border-color: transparent #333 transparent transparent;
    right: auto;
    left: -10px;
    top: 50%;
    margin-top: -5px;
  }
}

.clearing-thumbs,
[data-clearing] {
  *zoom: 1;
  margin-bottom: 0;
  margin-left: 0;
  list-style: none;
}

.clearing-thumbs:after,
.clearing-thumbs:before,
[data-clearing]:after,
[data-clearing]:before {
  content: " ";
  display: table;
}

.clearing-thumbs:after,
[data-clearing]:after {
  clear: both;
}

.clearing-thumbs li,
[data-clearing] li {
  float: left;
  margin-right: 10px;
}

.clearing-thumbs[class*="block-grid-"] li,
[data-clearing][class*="block-grid-"] li {
  margin-right: 0;
}

.clearing-blackout {
  background: #333;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 998;
}

.clearing-blackout .clearing-close {
  display: block;
}

.clearing-container {
  position: relative;
  z-index: 998;
  height: 100%;
  overflow: hidden;
  margin: 0;
}

.clearing-touch-label {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #aaa;
  font-size: 0.6em;
}

.visible-img {
  height: 95%;
  position: relative;
}

.visible-img img {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -50%;
  max-height: 100%;
  max-width: 100%;
}

.clearing-caption {
  color: #ccc;
  font-size: 0.875em;
  line-height: 1.3;
  margin-bottom: 0;
  text-align: center;
  bottom: 0;
  background: #333;
  width: 100%;
  padding: 10px 30px 20px;
  position: absolute;
  left: 0;
}

.clearing-close {
  z-index: 999;
  padding-left: 20px;
  padding-top: 10px;
  font-size: 30px;
  line-height: 1;
  color: #ccc;
  display: none;
}

.clearing-close:focus,
.clearing-close:hover {
  color: #ccc;
}

.clearing-assembled .clearing-container {
  height: 100%;
}

.clearing-assembled .clearing-container .carousel > ul,
.clearing-feature li {
  display: none;
}

.clearing-feature li.clearing-featured-img {
  display: block;
}

@media only screen and (min-width: 40.063em) {
  .joyride-expose-wrapper {
    background-color: #fff;
    position: absolute;
    border-radius: 3px;
    z-index: 102;
    -moz-box-shadow: 0 0 30px #fff;
    -webkit-box-shadow: 0 0 15px #fff;
    box-shadow: 0 0 15px #fff;
  }

  .joyride-expose-cover {
    background: 0 0;
    border-radius: 3px;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
  }

  @media only screen and (min-width: 48em) {
    .joyride-tip-guide {
      width: 300px;
      left: inherit;
    }

    .joyride-tip-guide .joyride-nub.bottom {
      border-color: #333 !important;
      border-bottom-color: transparent !important;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      bottom: -20px;
    }

    .joyride-tip-guide .joyride-nub.right {
      border-color: #333 !important;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      top: 22px;
      left: auto;
      right: -20px;
    }

    .joyride-tip-guide .joyride-nub.left {
      border-color: #333 !important;
      border-top-color: transparent !important;
      border-left-color: transparent !important;
      border-bottom-color: transparent !important;
      top: 22px;
      left: -20px;
      right: auto;
    }
  }

  .text-left {
    text-align: left !important;
  }

  .text-right {
    text-align: right !important;
  }

  .text-center {
    text-align: center !important;
  }

  .text-justify {
    text-align: justify !important;
  }

  @media only screen and (max-width: 767px) {
    .small-only-text-left {
      text-align: left !important;
    }

    .small-only-text-right {
      text-align: right !important;
    }

    .small-only-text-center {
      text-align: center !important;
    }

    .small-only-text-justify {
      text-align: justify !important;
    }
  }

  @media only screen {
    .small-text-left {
      text-align: left !important;
    }

    .small-text-right {
      text-align: right !important;
    }

    .small-text-center {
      text-align: center !important;
    }

    .small-text-justify {
      text-align: justify !important;
    }
  }

  @media only screen and (min-width: 40.063em) and (max-width: 64em) {
    .medium-only-text-left {
      text-align: left !important;
    }

    .medium-only-text-right {
      text-align: right !important;
    }

    .medium-only-text-center {
      text-align: center !important;
    }

    .medium-only-text-justify {
      text-align: justify !important;
    }
  }

  @media only screen and (min-width: 40.063em) {
    .medium-text-left {
      text-align: left !important;
    }

    .medium-text-right {
      text-align: right !important;
    }

    .medium-text-center {
      text-align: center !important;
    }

    .medium-text-justify {
      text-align: justify !important;
    }
  }

  @media only screen and (min-width: 64.063em) and (max-width: 90em) {
    .large-only-text-left {
      text-align: left !important;
    }

    .large-only-text-right {
      text-align: right !important;
    }

    .large-only-text-center {
      text-align: center !important;
    }

    .large-only-text-justify {
      text-align: justify !important;
    }
  }

  @media only screen and (min-width: 64.063em) {
    .large-text-left {
      text-align: left !important;
    }

    .large-text-right {
      text-align: right !important;
    }

    .large-text-center {
      text-align: center !important;
    }

    .large-text-justify {
      text-align: justify !important;
    }
  }

  @media only screen and (min-width: 90.063em) and (max-width: 120em) {
    .xlarge-only-text-left {
      text-align: left !important;
    }

    .xlarge-only-text-right {
      text-align: right !important;
    }

    .xlarge-only-text-center {
      text-align: center !important;
    }

    .xlarge-only-text-justify {
      text-align: justify !important;
    }
  }

  @media only screen and (min-width: 90.063em) {
    .xlarge-text-left {
      text-align: left !important;
    }

    .xlarge-text-right {
      text-align: right !important;
    }

    .xlarge-text-center {
      text-align: center !important;
    }

    .xlarge-text-justify {
      text-align: justify !important;
    }
  }

  @media only screen and (min-width: 120.063em) and (max-width: 99999999em) {
    .xxlarge-only-text-left {
      text-align: left !important;
    }

    .xxlarge-only-text-right {
      text-align: right !important;
    }

    .xxlarge-only-text-center {
      text-align: center !important;
    }

    .xxlarge-only-text-justify {
      text-align: justify !important;
    }
  }

  @media only screen and (min-width: 120.063em) {
    .xxlarge-text-left {
      text-align: left !important;
    }

    .xxlarge-text-right {
      text-align: right !important;
    }

    .xxlarge-text-center {
      text-align: center !important;
    }

    .xxlarge-text-justify {
      text-align: justify !important;
    }
  }

  .off-canvas-wrap {
    -webkit-backface-visibility: hidden;
    position: relative;
    width: 100%;
    overflow-x: hidden;
  }

  .off-canvas-wrap.move-left,
  .off-canvas-wrap.move-right {
    height: 100%;
  }

  nav.tab-bar {
    -webkit-backface-visibility: hidden;
    background: #333;
    color: #fff;
    height: 2.8125rem;
    line-height: 2.8125rem;
    position: relative;
  }

  nav.tab-bar h1,
  nav.tab-bar h2,
  nav.tab-bar h3,
  nav.tab-bar h4,
  nav.tab-bar h5,
  nav.tab-bar h6 {
    color: #fff;
    font-weight: 700;
    line-height: 2.8125rem;
    margin: 0;
  }

  nav.tab-bar h1,
  nav.tab-bar h2,
  nav.tab-bar h3,
  nav.tab-bar h4 {
    font-size: 1.125rem;
  }

  section.left-small {
    width: 2.8125rem;
    height: 2.8125rem;
    position: absolute;
    top: 0;
    border-right: solid 1px #1a1a1a;
    box-shadow: 1px 0 0 #4e4e4e;
    left: 0;
  }

  section.right-small {
    width: 2.8125rem;
    height: 2.8125rem;
    position: absolute;
    top: 0;
    border-left: solid 1px #4e4e4e;
    box-shadow: -1px 0 0 #1a1a1a;
    right: 0;
  }

  section.tab-bar-section {
    padding: 0 0.625rem;
    position: absolute;
    text-align: center;
    height: 2.8125rem;
    top: 0;
  }

  @media only screen and (min-width: 40.063em) {
    section.tab-bar-section {
      text-align: left;
    }
  }

  section.tab-bar-section.left {
    left: 0;
    right: 2.8125rem;
  }

  section.tab-bar-section.right {
    left: 2.8125rem;
    right: 0;
  }

  section.tab-bar-section.middle {
    left: 2.8125rem;
    right: 2.8125rem;
  }

  a.menu-icon {
    text-indent: 2.1875rem;
    width: 2.8125rem;
    height: 2.8125rem;
    display: block;
    line-height: 2.0625rem;
    padding: 0;
    color: #fff;
    position: relative;
  }

  a.menu-icon span {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0;
    left: 0.8125rem;
    top: 0.3125rem;
    -webkit-box-shadow: 1px 10px 1px 1px white, 1px 16px 1px 1px white,
      1px 22px 1px 1px #fff;
    box-shadow: 0 10px 0 1px white, 0 16px 0 1px white, 0 22px 0 1px #fff;
  }

  a.menu-icon:hover span {
    -webkit-box-shadow: 1px 10px 1px 1px #b3b3b3, 1px 16px 1px 1px #b3b3b3,
      1px 22px 1px 1px #b3b3b3;
    box-shadow: 0 10px 0 1px #b3b3b3, 0 16px 0 1px #b3b3b3, 0 22px 0 1px #b3b3b3;
  }

  .left-off-canvas-menu {
    -webkit-backface-visibility: hidden;
    width: 250px;
    top: 0;
    bottom: 0;
    position: absolute;
    overflow-y: auto;
    background: #333;
    z-index: 1001;
    box-sizing: content-box;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    left: 0;
  }

  .left-off-canvas-menu * {
    -webkit-backface-visibility: hidden;
  }

  .right-off-canvas-menu {
    -webkit-backface-visibility: hidden;
    width: 250px;
    top: 0;
    bottom: 0;
    position: absolute;
    overflow-y: auto;
    background: #333;
    z-index: 1001;
    box-sizing: content-box;
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    right: 0;
  }

  ul.off-canvas-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  ul.off-canvas-list li label {
    padding: 0.3rem 0.9375rem;
    color: #999;
    text-transform: uppercase;
    font-weight: 700;
    background: #444;
    border-top: 1px solid #5e5e5e;
    border-bottom: none;
    margin: 0;
  }

  ul.off-canvas-list li a {
    display: block;
    padding: 0.66667rem;
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid #262626;
  }

  .move-right > .inner-wrap {
    -webkit-transform: translate3d(250px, 0, 0);
    -moz-transform: translate3d(250px, 0, 0);
    -ms-transform: translate3d(250px, 0, 0);
    -o-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
  }

  .move-right a.exit-off-canvas {
    -webkit-backface-visibility: hidden;
    transition: background 300ms ease;
    cursor: pointer;
    box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1002;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  @media only screen and (min-width: 40.063em) {
    .move-right a.exit-off-canvas:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }

  .move-left > .inner-wrap {
    -webkit-transform: translate3d(-250px, 0, 0);
    -moz-transform: translate3d(-250px, 0, 0);
    -ms-transform: translate3d(-250px, 0, 0);
    -o-transform: translate3d(-250px, 0, 0);
    transform: translate3d(-250px, 0, 0);
  }

  .move-left a.exit-off-canvas {
    -webkit-backface-visibility: hidden;
    transition: background 300ms ease;
    cursor: pointer;
    box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1002;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  @media only screen and (min-width: 40.063em) {
    .move-left a.exit-off-canvas:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }

  .csstransforms.no-csstransforms3d .left-off-canvas-menu {
    -webkit-transform: translate(-100%, 0);
    -moz-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    -o-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }

  .csstransforms.no-csstransforms3d .right-off-canvas-menu {
    -webkit-transform: translate(100%, 0);
    -moz-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    -o-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }

  .csstransforms.no-csstransforms3d .move-left > .inner-wrap {
    -webkit-transform: translate(-250px, 0);
    -moz-transform: translate(-250px, 0);
    -ms-transform: translate(-250px, 0);
    -o-transform: translate(-250px, 0);
    transform: translate(-250px, 0);
  }

  .csstransforms.no-csstransforms3d .move-right > .inner-wrap {
    -webkit-transform: translate(250px, 0);
    -moz-transform: translate(250px, 0);
    -ms-transform: translate(250px, 0);
    -o-transform: translate(250px, 0);
    transform: translate(250px, 0);
  }

  .no-csstransforms .left-off-canvas-menu {
    left: -250px;
  }

  .no-csstransforms .right-off-canvas-menu {
    right: -250px;
  }

  .no-csstransforms .move-left > .inner-wrap {
    right: 250px;
  }

  .no-csstransforms .move-right > .inner-wrap {
    left: 250px;
  }

  @media only screen and (max-width: 767px) {
    .f-dropdown {
      max-width: 100%;
      left: 0;
    }
  }

  .f-dropdown {
    position: absolute;
    left: -9999px;
    list-style: none;
    margin-left: 0;
    width: 100%;
    max-height: none;
    height: auto;
    background: #fff;
    border: solid 1px #ccc;
    font-size: 16px;
    z-index: 99;
    margin-top: 2px;
    max-width: 200px;
  }

  .f-dropdown > :first-child {
    margin-top: 0;
  }

  .f-dropdown > :last-child {
    margin-bottom: 0;
  }

  .f-dropdown:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    border-color: transparent transparent #fff;
    border-bottom-style: solid;
    position: absolute;
    top: -12px;
    left: 10px;
    z-index: 99;
  }

  .f-dropdown:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: inset 7px;
    border-color: transparent transparent #ccc;
    border-bottom-style: solid;
    position: absolute;
    top: -14px;
    left: 9px;
    z-index: 98;
  }

  .f-dropdown.right:before {
    left: auto;
    right: 10px;
  }

  .f-dropdown.right:after {
    left: auto;
    right: 9px;
  }

  .f-dropdown li {
    font-size: 0.875rem;
    cursor: pointer;
    line-height: 1.125rem;
    margin: 0;
  }

  .f-dropdown li:focus,
  .f-dropdown li:hover {
    background: #eee;
  }

  .f-dropdown li a {
    display: block;
    padding: 0.5rem;
    color: #555;
  }

  .f-dropdown.content {
    position: absolute;
    left: -9999px;
    list-style: none;
    margin-left: 0;
    padding: 1.25rem;
    width: 100%;
    height: auto;
    max-height: none;
    background: #fff;
    border: solid 1px #ccc;
    font-size: 16px;
    z-index: 99;
    max-width: 200px;
  }

  .f-dropdown.content > :first-child {
    margin-top: 0;
  }

  .f-dropdown.content > :last-child {
    margin-bottom: 0;
  }

  .f-dropdown.tiny {
    max-width: 200px;
  }

  .f-dropdown.small {
    max-width: 300px;
  }

  .f-dropdown.medium {
    max-width: 500px;
  }

  .f-dropdown.large {
    max-width: 800px;
  }

  select::-ms-expand {
    display: none;
  }

  [class*="block-grid-"] {
    display: block;
    padding: 0;
    margin: 0 -0.625rem;
    *zoom: 1;
  }

  [class*="block-grid-"]:after,
  [class*="block-grid-"]:before {
    content: " ";
    display: table;
  }

  [class*="block-grid-"]:after {
    clear: both;
  }

  [class*="block-grid-"] > li {
    display: block;
    height: auto;
    float: left;
    padding: 0 0.625rem 1.25rem;
  }

  @media only screen {
    .small-block-grid-1 > li {
      width: 100%;
      list-style: none;
    }

    .small-block-grid-1 > li:nth-of-type(n) {
      clear: none;
    }

    .small-block-grid-1 > li:nth-of-type(1n + 1) {
      clear: both;
    }

    .small-block-grid-2 > li {
      width: 50%;
      list-style: none;
    }

    .small-block-grid-2 > li:nth-of-type(n) {
      clear: none;
    }

    .small-block-grid-2 > li:nth-of-type(2n + 1) {
      clear: both;
    }

    .small-block-grid-3 > li {
      width: 33.33333%;
      list-style: none;
    }

    .small-block-grid-3 > li:nth-of-type(n) {
      clear: none;
    }

    .small-block-grid-3 > li:nth-of-type(3n + 1) {
      clear: both;
    }

    .small-block-grid-4 > li {
      width: 25%;
      list-style: none;
    }

    .small-block-grid-4 > li:nth-of-type(n) {
      clear: none;
    }

    .small-block-grid-4 > li:nth-of-type(4n + 1) {
      clear: both;
    }

    .small-block-grid-5 > li {
      width: 20%;
      list-style: none;
    }

    .small-block-grid-5 > li:nth-of-type(n) {
      clear: none;
    }

    .small-block-grid-5 > li:nth-of-type(5n + 1) {
      clear: both;
    }

    .small-block-grid-6 > li {
      width: 16.66667%;
      list-style: none;
    }

    .small-block-grid-6 > li:nth-of-type(n) {
      clear: none;
    }

    .small-block-grid-6 > li:nth-of-type(6n + 1) {
      clear: both;
    }

    .small-block-grid-7 > li {
      width: 14.28571%;
      list-style: none;
    }

    .small-block-grid-7 > li:nth-of-type(n) {
      clear: none;
    }

    .small-block-grid-7 > li:nth-of-type(7n + 1) {
      clear: both;
    }

    .small-block-grid-8 > li {
      width: 12.5%;
      list-style: none;
    }

    .small-block-grid-8 > li:nth-of-type(n) {
      clear: none;
    }

    .small-block-grid-8 > li:nth-of-type(8n + 1) {
      clear: both;
    }

    .small-block-grid-9 > li {
      width: 11.11111%;
      list-style: none;
    }

    .small-block-grid-9 > li:nth-of-type(n) {
      clear: none;
    }

    .small-block-grid-9 > li:nth-of-type(9n + 1) {
      clear: both;
    }

    .small-block-grid-10 > li {
      width: 10%;
      list-style: none;
    }

    .small-block-grid-10 > li:nth-of-type(n) {
      clear: none;
    }

    .small-block-grid-10 > li:nth-of-type(10n + 1) {
      clear: both;
    }

    .small-block-grid-11 > li {
      width: 9.09091%;
      list-style: none;
    }

    .small-block-grid-11 > li:nth-of-type(n) {
      clear: none;
    }

    .small-block-grid-11 > li:nth-of-type(11n + 1) {
      clear: both;
    }

    .small-block-grid-12 > li {
      width: 8.33333%;
      list-style: none;
    }

    .small-block-grid-12 > li:nth-of-type(n) {
      clear: none;
    }

    .small-block-grid-12 > li:nth-of-type(12n + 1) {
      clear: both;
    }
  }

  @media only screen and (min-width: 40.063em) {
    .medium-block-grid-1 > li {
      width: 100%;
      list-style: none;
    }

    .medium-block-grid-1 > li:nth-of-type(n) {
      clear: none;
    }

    .medium-block-grid-1 > li:nth-of-type(1n + 1) {
      clear: both;
    }

    .medium-block-grid-2 > li {
      width: 50%;
      list-style: none;
    }

    .medium-block-grid-2 > li:nth-of-type(n) {
      clear: none;
    }

    .medium-block-grid-2 > li:nth-of-type(2n + 1) {
      clear: both;
    }

    .medium-block-grid-3 > li {
      width: 33.33333%;
      list-style: none;
    }

    .medium-block-grid-3 > li:nth-of-type(n) {
      clear: none;
    }

    .medium-block-grid-3 > li:nth-of-type(3n + 1) {
      clear: both;
    }

    .medium-block-grid-4 > li {
      width: 25%;
      list-style: none;
    }

    .medium-block-grid-4 > li:nth-of-type(n) {
      clear: none;
    }

    .medium-block-grid-4 > li:nth-of-type(4n + 1) {
      clear: both;
    }

    .medium-block-grid-5 > li {
      width: 20%;
      list-style: none;
    }

    .medium-block-grid-5 > li:nth-of-type(n) {
      clear: none;
    }

    .medium-block-grid-5 > li:nth-of-type(5n + 1) {
      clear: both;
    }

    .medium-block-grid-6 > li {
      width: 16.66667%;
      list-style: none;
    }

    .medium-block-grid-6 > li:nth-of-type(n) {
      clear: none;
    }

    .medium-block-grid-6 > li:nth-of-type(6n + 1) {
      clear: both;
    }

    .medium-block-grid-7 > li {
      width: 14.28571%;
      list-style: none;
    }

    .medium-block-grid-7 > li:nth-of-type(n) {
      clear: none;
    }

    .medium-block-grid-7 > li:nth-of-type(7n + 1) {
      clear: both;
    }

    .medium-block-grid-8 > li {
      width: 12.5%;
      list-style: none;
    }

    .medium-block-grid-8 > li:nth-of-type(n) {
      clear: none;
    }

    .medium-block-grid-8 > li:nth-of-type(8n + 1) {
      clear: both;
    }

    .medium-block-grid-9 > li {
      width: 11.11111%;
      list-style: none;
    }

    .medium-block-grid-9 > li:nth-of-type(n) {
      clear: none;
    }

    .medium-block-grid-9 > li:nth-of-type(9n + 1) {
      clear: both;
    }

    .medium-block-grid-10 > li {
      width: 10%;
      list-style: none;
    }

    .medium-block-grid-10 > li:nth-of-type(n) {
      clear: none;
    }

    .medium-block-grid-10 > li:nth-of-type(10n + 1) {
      clear: both;
    }

    .medium-block-grid-11 > li {
      width: 9.09091%;
      list-style: none;
    }

    .medium-block-grid-11 > li:nth-of-type(n) {
      clear: none;
    }

    .medium-block-grid-11 > li:nth-of-type(11n + 1) {
      clear: both;
    }

    .medium-block-grid-12 > li {
      width: 8.33333%;
      list-style: none;
    }

    .medium-block-grid-12 > li:nth-of-type(n) {
      clear: none;
    }

    .medium-block-grid-12 > li:nth-of-type(12n + 1) {
      clear: both;
    }
  }

  @media only screen and (min-width: 64.063em) {
    .large-block-grid-1 > li {
      width: 100%;
      list-style: none;
    }

    .large-block-grid-1 > li:nth-of-type(n) {
      clear: none;
    }

    .large-block-grid-1 > li:nth-of-type(1n + 1) {
      clear: both;
    }

    .large-block-grid-2 > li {
      width: 50%;
      list-style: none;
    }

    .large-block-grid-2 > li:nth-of-type(n) {
      clear: none;
    }

    .large-block-grid-2 > li:nth-of-type(2n + 1) {
      clear: both;
    }

    .large-block-grid-3 > li {
      width: 33.33333%;
      list-style: none;
    }

    .large-block-grid-3 > li:nth-of-type(n) {
      clear: none;
    }

    .large-block-grid-3 > li:nth-of-type(3n + 1) {
      clear: both;
    }

    .large-block-grid-4 > li {
      width: 25%;
      list-style: none;
    }

    .large-block-grid-4 > li:nth-of-type(n) {
      clear: none;
    }

    .large-block-grid-4 > li:nth-of-type(4n + 1) {
      clear: both;
    }

    .large-block-grid-5 > li {
      width: 20%;
      list-style: none;
    }

    .large-block-grid-5 > li:nth-of-type(n) {
      clear: none;
    }

    .large-block-grid-5 > li:nth-of-type(5n + 1) {
      clear: both;
    }

    .large-block-grid-6 > li {
      width: 16.66667%;
      list-style: none;
    }

    .large-block-grid-6 > li:nth-of-type(n) {
      clear: none;
    }

    .large-block-grid-6 > li:nth-of-type(6n + 1) {
      clear: both;
    }

    .large-block-grid-7 > li {
      width: 14.28571%;
      list-style: none;
    }

    .large-block-grid-7 > li:nth-of-type(n) {
      clear: none;
    }

    .large-block-grid-7 > li:nth-of-type(7n + 1) {
      clear: both;
    }

    .large-block-grid-8 > li {
      width: 12.5%;
      list-style: none;
    }

    .large-block-grid-8 > li:nth-of-type(n) {
      clear: none;
    }

    .large-block-grid-8 > li:nth-of-type(8n + 1) {
      clear: both;
    }

    .large-block-grid-9 > li {
      width: 11.11111%;
      list-style: none;
    }

    .large-block-grid-9 > li:nth-of-type(n) {
      clear: none;
    }

    .large-block-grid-9 > li:nth-of-type(9n + 1) {
      clear: both;
    }

    .large-block-grid-10 > li {
      width: 10%;
      list-style: none;
    }

    .large-block-grid-10 > li:nth-of-type(n) {
      clear: none;
    }

    .large-block-grid-10 > li:nth-of-type(10n + 1) {
      clear: both;
    }

    .large-block-grid-11 > li {
      width: 9.09091%;
      list-style: none;
    }

    .large-block-grid-11 > li:nth-of-type(n) {
      clear: none;
    }

    .large-block-grid-11 > li:nth-of-type(11n + 1) {
      clear: both;
    }

    .large-block-grid-12 > li {
      width: 8.33333%;
      list-style: none;
    }

    .large-block-grid-12 > li:nth-of-type(n) {
      clear: none;
    }

    .large-block-grid-12 > li:nth-of-type(12n + 1) {
      clear: both;
    }
  }

  .keystroke,
  kbd {
    background-color: #ededed;
    border-color: #ddd;
    color: #222;
    border-style: solid;
    border-width: 1px;
    margin: 0;
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 0.875rem;
    padding: 0.125rem 0.25rem 0;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }

  .hide-for-large,
  .hide-for-large-only,
  .hide-for-large-up,
  .hide-for-medium,
  .hide-for-medium-only,
  .hide-for-medium-up,
  .hide-for-xlarge,
  .hide-for-xlarge-only,
  .hide-for-xlarge-up,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .show-for-large-down,
  .show-for-medium-down,
  .show-for-small,
  .show-for-small-only {
    display: inherit !important;
  }

  .hide-for-large-down,
  .hide-for-medium-down,
  .hide-for-small,
  .hide-for-small-only,
  .show-for-large,
  .show-for-large-only,
  .show-for-large-up,
  .show-for-medium,
  .show-for-medium-only,
  .show-for-medium-up,
  .show-for-xlarge,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up {
    display: none !important;
  }

  table.hide-for-large,
  table.hide-for-large-only,
  table.hide-for-large-up,
  table.hide-for-medium,
  table.hide-for-medium-only,
  table.hide-for-medium-up,
  table.hide-for-xlarge,
  table.hide-for-xlarge-only,
  table.hide-for-xlarge-up,
  table.hide-for-xxlarge-only,
  table.hide-for-xxlarge-up,
  table.show-for-large-down,
  table.show-for-medium-down,
  table.show-for-small,
  table.show-for-small-only {
    display: table;
  }

  thead.hide-for-large,
  thead.hide-for-large-only,
  thead.hide-for-large-up,
  thead.hide-for-medium,
  thead.hide-for-medium-only,
  thead.hide-for-medium-up,
  thead.hide-for-xlarge,
  thead.hide-for-xlarge-only,
  thead.hide-for-xlarge-up,
  thead.hide-for-xxlarge-only,
  thead.hide-for-xxlarge-up,
  thead.show-for-large-down,
  thead.show-for-medium-down,
  thead.show-for-small,
  thead.show-for-small-only {
    display: table-header-group !important;
  }

  tbody.hide-for-large,
  tbody.hide-for-large-only,
  tbody.hide-for-large-up,
  tbody.hide-for-medium,
  tbody.hide-for-medium-only,
  tbody.hide-for-medium-up,
  tbody.hide-for-xlarge,
  tbody.hide-for-xlarge-only,
  tbody.hide-for-xlarge-up,
  tbody.hide-for-xxlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.show-for-large-down,
  tbody.show-for-medium-down,
  tbody.show-for-small,
  tbody.show-for-small-only {
    display: table-row-group !important;
  }

  tr.hide-for-large,
  tr.hide-for-large-only,
  tr.hide-for-large-up,
  tr.hide-for-medium,
  tr.hide-for-medium-only,
  tr.hide-for-medium-up,
  tr.hide-for-xlarge,
  tr.hide-for-xlarge-only,
  tr.hide-for-xlarge-up,
  tr.hide-for-xxlarge-only,
  tr.hide-for-xxlarge-up,
  tr.show-for-large-down,
  tr.show-for-medium-down,
  tr.show-for-small,
  tr.show-for-small-only {
    display: table-row !important;
  }

  td.hide-for-large,
  td.hide-for-large-up,
  td.hide-for-medium,
  td.hide-for-medium-up,
  td.hide-for-xlarge,
  td.hide-for-xlarge-up,
  td.hide-for-xxlarge-up,
  td.show-for-large-down,
  td.show-for-medium-down,
  td.show-for-small,
  td.show-for-small-only,
  th.hide-for-large,
  th.hide-for-large-up,
  th.hide-for-medium,
  th.hide-for-medium-up,
  th.hide-for-xlarge,
  th.hide-for-xlarge-up,
  th.hide-for-xxlarge-up,
  th.show-for-large-down,
  th.show-for-medium-down,
  th.show-for-small,
  th.show-for-small-only {
    display: table-cell !important;
  }

  @media only screen and (min-width: 40.063em) {
    .hide-for-large,
    .hide-for-large-only,
    .hide-for-large-up,
    .hide-for-small,
    .hide-for-small-only,
    .hide-for-xlarge,
    .hide-for-xlarge-only,
    .hide-for-xlarge-up,
    .hide-for-xxlarge-only,
    .hide-for-xxlarge-up,
    .show-for-medium,
    .show-for-medium-down,
    .show-for-medium-only,
    .show-for-medium-up {
      display: inherit !important;
    }

    .hide-for-large-down,
    .hide-for-medium,
    .hide-for-medium-down,
    .hide-for-medium-only,
    .hide-for-medium-up,
    .show-for-large,
    .show-for-large-only,
    .show-for-large-up,
    .show-for-small,
    .show-for-small-only,
    .show-for-xlarge,
    .show-for-xlarge-only,
    .show-for-xlarge-up,
    .show-for-xxlarge-only,
    .show-for-xxlarge-up {
      display: none !important;
    }

    table.hide-for-large,
    table.hide-for-large-only,
    table.hide-for-large-up,
    table.hide-for-small,
    table.hide-for-small-only,
    table.hide-for-xlarge,
    table.hide-for-xlarge-only,
    table.hide-for-xlarge-up,
    table.hide-for-xxlarge-only,
    table.hide-for-xxlarge-up,
    table.show-for-medium,
    table.show-for-medium-down,
    table.show-for-medium-only,
    table.show-for-medium-up {
      display: table;
    }

    thead.hide-for-large,
    thead.hide-for-large-only,
    thead.hide-for-large-up,
    thead.hide-for-small,
    thead.hide-for-small-only,
    thead.hide-for-xlarge,
    thead.hide-for-xlarge-only,
    thead.hide-for-xlarge-up,
    thead.hide-for-xxlarge-only,
    thead.hide-for-xxlarge-up,
    thead.show-for-medium,
    thead.show-for-medium-down,
    thead.show-for-medium-only,
    thead.show-for-medium-up {
      display: table-header-group !important;
    }

    tbody.hide-for-large,
    tbody.hide-for-large-only,
    tbody.hide-for-large-up,
    tbody.hide-for-small,
    tbody.hide-for-small-only,
    tbody.hide-for-xlarge,
    tbody.hide-for-xlarge-only,
    tbody.hide-for-xlarge-up,
    tbody.hide-for-xxlarge-only,
    tbody.hide-for-xxlarge-up,
    tbody.show-for-medium,
    tbody.show-for-medium-down,
    tbody.show-for-medium-only,
    tbody.show-for-medium-up {
      display: table-row-group !important;
    }

    tr.hide-for-large,
    tr.hide-for-large-only,
    tr.hide-for-large-up,
    tr.hide-for-small,
    tr.hide-for-small-only,
    tr.hide-for-xlarge,
    tr.hide-for-xlarge-only,
    tr.hide-for-xlarge-up,
    tr.hide-for-xxlarge-only,
    tr.hide-for-xxlarge-up,
    tr.show-for-medium,
    tr.show-for-medium-down,
    tr.show-for-medium-only,
    tr.show-for-medium-up {
      display: table-row !important;
    }

    td.hide-for-large,
    td.hide-for-large-only,
    td.hide-for-large-up,
    td.hide-for-small,
    td.hide-for-small-only,
    td.hide-for-xlarge,
    td.hide-for-xlarge-only,
    td.hide-for-xlarge-up,
    td.hide-for-xxlarge-only,
    td.hide-for-xxlarge-up,
    td.show-for-medium,
    td.show-for-medium-down,
    td.show-for-medium-only,
    td.show-for-medium-up,
    th.hide-for-large,
    th.hide-for-large-only,
    th.hide-for-large-up,
    th.hide-for-small,
    th.hide-for-small-only,
    th.hide-for-xlarge,
    th.hide-for-xlarge-only,
    th.hide-for-xlarge-up,
    th.hide-for-xxlarge-only,
    th.hide-for-xxlarge-up,
    th.show-for-medium,
    th.show-for-medium-down,
    th.show-for-medium-only,
    th.show-for-medium-up {
      display: table-cell !important;
    }
  }

  @media only screen and (min-width: 64.063em) {
    .hide-for-medium,
    .hide-for-medium-down,
    .hide-for-medium-only,
    .hide-for-small,
    .hide-for-small-only,
    .hide-for-xlarge,
    .hide-for-xlarge-only,
    .hide-for-xlarge-up,
    .hide-for-xxlarge-only,
    .hide-for-xxlarge-up,
    .show-for-large,
    .show-for-large-only,
    .show-for-large-up,
    .show-for-medium-up {
      display: inherit !important;
    }

    .hide-for-large,
    .hide-for-large-only,
    .hide-for-large-up,
    .show-for-medium,
    .show-for-medium-down,
    .show-for-medium-only,
    .show-for-small-only,
    .show-for-xlarge,
    .show-for-xlarge-only,
    .show-for-xlarge-up,
    .show-for-xxlarge-only,
    .show-for-xxlarge-up {
      display: none !important;
    }

    table.hide-for-medium,
    table.hide-for-medium-down,
    table.hide-for-medium-only,
    table.hide-for-small,
    table.hide-for-small-only,
    table.hide-for-xlarge,
    table.hide-for-xlarge-only,
    table.hide-for-xlarge-up,
    table.hide-for-xxlarge-only,
    table.hide-for-xxlarge-up,
    table.show-for-large,
    table.show-for-large-only,
    table.show-for-large-up,
    table.show-for-medium-up {
      display: table;
    }

    thead.hide-for-medium,
    thead.hide-for-medium-down,
    thead.hide-for-medium-only,
    thead.hide-for-small,
    thead.hide-for-small-only,
    thead.hide-for-xlarge,
    thead.hide-for-xlarge-only,
    thead.hide-for-xlarge-up,
    thead.hide-for-xxlarge-only,
    thead.hide-for-xxlarge-up,
    thead.show-for-large,
    thead.show-for-large-only,
    thead.show-for-large-up,
    thead.show-for-medium-up {
      display: table-header-group !important;
    }

    tbody.hide-for-medium,
    tbody.hide-for-medium-down,
    tbody.hide-for-medium-only,
    tbody.hide-for-small,
    tbody.hide-for-small-only,
    tbody.hide-for-xlarge,
    tbody.hide-for-xlarge-only,
    tbody.hide-for-xlarge-up,
    tbody.hide-for-xxlarge-only,
    tbody.hide-for-xxlarge-up,
    tbody.show-for-large,
    tbody.show-for-large-only,
    tbody.show-for-large-up,
    tbody.show-for-medium-up {
      display: table-row-group !important;
    }

    tr.hide-for-medium,
    tr.hide-for-medium-down,
    tr.hide-for-medium-only,
    tr.hide-for-small,
    tr.hide-for-small-only,
    tr.hide-for-xlarge,
    tr.hide-for-xlarge-only,
    tr.hide-for-xlarge-up,
    tr.hide-for-xxlarge-only,
    tr.hide-for-xxlarge-up,
    tr.show-for-large,
    tr.show-for-large-only,
    tr.show-for-large-up,
    tr.show-for-medium-up {
      display: table-row !important;
    }

    td.hide-for-medium,
    td.hide-for-medium-down,
    td.hide-for-medium-only,
    td.hide-for-small,
    td.hide-for-small-only,
    td.hide-for-xlarge,
    td.hide-for-xlarge-only,
    td.hide-for-xlarge-up,
    td.hide-for-xxlarge-only,
    td.hide-for-xxlarge-up,
    td.show-for-large,
    td.show-for-large-only,
    td.show-for-large-up,
    td.show-for-medium-up,
    th.hide-for-medium,
    th.hide-for-medium-down,
    th.hide-for-medium-only,
    th.hide-for-small,
    th.hide-for-small-only,
    th.hide-for-xlarge,
    th.hide-for-xlarge-only,
    th.hide-for-xlarge-up,
    th.hide-for-xxlarge-only,
    th.hide-for-xxlarge-up,
    th.show-for-large,
    th.show-for-large-only,
    th.show-for-large-up,
    th.show-for-medium-up {
      display: table-cell !important;
    }
  }

  @media only screen and (min-width: 90.063em) {
    .hide-for-large-only,
    .hide-for-medium,
    .hide-for-medium-down,
    .hide-for-medium-only,
    .hide-for-small,
    .hide-for-small-only,
    .hide-for-xxlarge-only,
    .hide-for-xxlarge-up,
    .show-for-large-up,
    .show-for-medium-up,
    .show-for-xlarge,
    .show-for-xlarge-only,
    .show-for-xlarge-up {
      display: inherit !important;
    }

    .hide-for-xlarge,
    .hide-for-xlarge-only,
    .hide-for-xlarge-up,
    .show-for-large,
    .show-for-large-down,
    .show-for-large-only,
    .show-for-medium,
    .show-for-medium-down,
    .show-for-medium-only,
    .show-for-small-only,
    .show-for-xxlarge-only,
    .show-for-xxlarge-up {
      display: none !important;
    }

    table.hide-for-large-only,
    table.hide-for-medium,
    table.hide-for-medium-down,
    table.hide-for-medium-only,
    table.hide-for-small,
    table.hide-for-small-only,
    table.hide-for-xxlarge-only,
    table.hide-for-xxlarge-up,
    table.show-for-large-up,
    table.show-for-medium-up,
    table.show-for-xlarge,
    table.show-for-xlarge-only,
    table.show-for-xlarge-up {
      display: table;
    }

    thead.hide-for-large-only,
    thead.hide-for-medium,
    thead.hide-for-medium-down,
    thead.hide-for-medium-only,
    thead.hide-for-small,
    thead.hide-for-small-only,
    thead.hide-for-xxlarge-only,
    thead.hide-for-xxlarge-up,
    thead.show-for-large-up,
    thead.show-for-medium-up,
    thead.show-for-xlarge,
    thead.show-for-xlarge-only,
    thead.show-for-xlarge-up {
      display: table-header-group !important;
    }

    tbody.hide-for-large-only,
    tbody.hide-for-medium,
    tbody.hide-for-medium-down,
    tbody.hide-for-medium-only,
    tbody.hide-for-small,
    tbody.hide-for-small-only,
    tbody.hide-for-xxlarge-only,
    tbody.hide-for-xxlarge-up,
    tbody.show-for-large-up,
    tbody.show-for-medium-up,
    tbody.show-for-xlarge,
    tbody.show-for-xlarge-only,
    tbody.show-for-xlarge-up {
      display: table-row-group !important;
    }

    tr.hide-for-large-only,
    tr.hide-for-medium,
    tr.hide-for-medium-down,
    tr.hide-for-medium-only,
    tr.hide-for-small,
    tr.hide-for-small-only,
    tr.hide-for-xxlarge-only,
    tr.hide-for-xxlarge-up,
    tr.show-for-large-up,
    tr.show-for-medium-up,
    tr.show-for-xlarge,
    tr.show-for-xlarge-only,
    tr.show-for-xlarge-up {
      display: table-row !important;
    }

    td.hide-for-large-only,
    td.hide-for-medium,
    td.hide-for-medium-down,
    td.hide-for-medium-only,
    td.hide-for-small,
    td.hide-for-small-only,
    td.hide-for-xxlarge-only,
    td.hide-for-xxlarge-up,
    td.show-for-large-up,
    td.show-for-medium-up,
    td.show-for-xlarge,
    td.show-for-xlarge-only,
    td.show-for-xlarge-up,
    th.hide-for-large-only,
    th.hide-for-medium,
    th.hide-for-medium-down,
    th.hide-for-medium-only,
    th.hide-for-small,
    th.hide-for-small-only,
    th.hide-for-xxlarge-only,
    th.hide-for-xxlarge-up,
    th.show-for-large-up,
    th.show-for-medium-up,
    th.show-for-xlarge,
    th.show-for-xlarge-only,
    th.show-for-xlarge-up {
      display: table-cell !important;
    }
  }

  @media only screen and (min-width: 120.063em) {
    .hide-for-large-only,
    .hide-for-medium,
    .hide-for-medium-down,
    .hide-for-medium-only,
    .hide-for-small,
    .hide-for-small-only,
    .hide-for-xlarge-only,
    .show-for-large-up,
    .show-for-medium-up,
    .show-for-xlarge-up,
    .show-for-xxlarge-only,
    .show-for-xxlarge-up {
      display: inherit !important;
    }

    .hide-for-xlarge,
    .hide-for-xxlarge-only,
    .hide-for-xxlarge-up,
    .show-for-large,
    .show-for-large-down,
    .show-for-large-only,
    .show-for-medium,
    .show-for-medium-down,
    .show-for-medium-only,
    .show-for-small-only,
    .show-for-xlarge-only {
      display: none !important;
    }

    table.hide-for-medium,
    table.hide-for-medium-down,
    table.hide-for-medium-only,
    table.hide-for-small,
    table.hide-for-small-only,
    table.hide-for-xlarge-only,
    table.show-for-large-up,
    table.show-for-medium-up,
    table.show-for-xlarge-up,
    table.show-for-xxlarge-only,
    table.show-for-xxlarge-up {
      display: table;
    }

    thead.hide-for-medium,
    thead.hide-for-medium-down,
    thead.hide-for-medium-only,
    thead.hide-for-small,
    thead.hide-for-small-only,
    thead.hide-for-xlarge-only,
    thead.show-for-large-up,
    thead.show-for-medium-up,
    thead.show-for-xlarge-up,
    thead.show-for-xxlarge-only,
    thead.show-for-xxlarge-up {
      display: table-header-group !important;
    }

    tbody.hide-for-medium,
    tbody.hide-for-medium-down,
    tbody.hide-for-medium-only,
    tbody.hide-for-small,
    tbody.hide-for-small-only,
    tbody.hide-for-xlarge-only,
    tbody.show-for-large-up,
    tbody.show-for-medium-up,
    tbody.show-for-xlarge-up,
    tbody.show-for-xxlarge-only,
    tbody.show-for-xxlarge-up {
      display: table-row-group !important;
    }

    tr.hide-for-medium,
    tr.hide-for-medium-down,
    tr.hide-for-medium-only,
    tr.hide-for-small,
    tr.hide-for-small-only,
    tr.hide-for-xlarge-only,
    tr.show-for-large-up,
    tr.show-for-medium-up,
    tr.show-for-xlarge-up,
    tr.show-for-xxlarge-only,
    tr.show-for-xxlarge-up {
      display: table-row !important;
    }

    td.hide-for-medium,
    td.hide-for-medium-down,
    td.hide-for-medium-only,
    td.hide-for-small,
    td.hide-for-small-only,
    td.hide-for-xlarge-only,
    td.show-for-large-up,
    td.show-for-medium-up,
    td.show-for-xlarge-up,
    td.show-for-xxlarge-only,
    td.show-for-xxlarge-up,
    th.hide-for-medium,
    th.hide-for-medium-down,
    th.hide-for-medium-only,
    th.hide-for-small,
    th.hide-for-small-only,
    th.hide-for-xlarge-only,
    th.show-for-large-up,
    th.show-for-medium-up,
    th.show-for-xlarge-up,
    th.show-for-xxlarge-only,
    th.show-for-xxlarge-up {
      display: table-cell !important;
    }
  }

  .hide-for-portrait,
  .show-for-landscape {
    display: inherit !important;
  }

  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }

  table.hide-for-landscape,
  table.show-for-portrait {
    display: table;
  }

  thead.hide-for-landscape,
  thead.show-for-portrait {
    display: table-header-group !important;
  }

  tbody.hide-for-landscape,
  tbody.show-for-portrait {
    display: table-row-group !important;
  }

  tr.hide-for-landscape,
  tr.show-for-portrait {
    display: table-row !important;
  }

  td.hide-for-landscape,
  td.show-for-portrait,
  th.hide-for-landscape,
  th.show-for-portrait {
    display: table-cell !important;
  }

  @media only screen and (orientation: landscape) {
    .hide-for-portrait,
    .show-for-landscape {
      display: inherit !important;
    }

    .hide-for-landscape,
    .show-for-portrait {
      display: none !important;
    }

    table.hide-for-portrait,
    table.show-for-landscape {
      display: table;
    }

    thead.hide-for-portrait,
    thead.show-for-landscape {
      display: table-header-group !important;
    }

    tbody.hide-for-portrait,
    tbody.show-for-landscape {
      display: table-row-group !important;
    }

    tr.hide-for-portrait,
    tr.show-for-landscape {
      display: table-row !important;
    }

    td.hide-for-portrait,
    td.show-for-landscape,
    th.hide-for-portrait,
    th.show-for-landscape {
      display: table-cell !important;
    }
  }

  @media only screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: inherit !important;
    }

    .hide-for-portrait,
    .show-for-landscape {
      display: none !important;
    }

    table.hide-for-landscape,
    table.show-for-portrait {
      display: table;
    }

    thead.hide-for-landscape,
    thead.show-for-portrait {
      display: table-header-group !important;
    }

    tbody.hide-for-landscape,
    tbody.show-for-portrait {
      display: table-row-group !important;
    }

    tr.hide-for-landscape,
    tr.show-for-portrait {
      display: table-row !important;
    }

    td.hide-for-landscape,
    td.show-for-portrait,
    th.hide-for-landscape,
    th.show-for-portrait {
      display: table-cell !important;
    }
  }

  .show-for-touch {
    display: none !important;
  }

  .hide-for-touch,
  .touch .show-for-touch {
    display: inherit !important;
  }

  .touch .hide-for-touch {
    display: none !important;
  }

  .touch table.show-for-touch,
  table.hide-for-touch {
    display: table;
  }

  .touch thead.show-for-touch,
  thead.hide-for-touch {
    display: table-header-group !important;
  }

  .touch tbody.show-for-touch,
  tbody.hide-for-touch {
    display: table-row-group !important;
  }

  .touch tr.show-for-touch,
  tr.hide-for-touch {
    display: table-row !important;
  }

  .touch td.show-for-touch,
  .touch th.show-for-touch,
  td.hide-for-touch,
  th.hide-for-touch {
    display: table-cell !important;
  }
}
